<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <h1
        class="font-size-sm-100 font-weight-boldest text-white mt-15"
        style="font-size: 150px;"
      >
        404
      </h1>
      <p class="font-size-h3 font-weight-light text-white">
        {{ $t("GENERIC.404") }}
      </p>

      <div class="row mt-6">
        <div class="col-lg-1">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="$router.push('/manager/home')"
          >
            <inline-svg
              src="/media/icons/arrow-return-primary.svg"
              class="mr-3"
            />
            {{ $t("GENERIC.BACK") }}
          </button>
        </div>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Error-404",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/imagen-bienvenido.jpg";
    }
  }
};
</script>
